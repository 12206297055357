<!-- 登记观看 -->
<template>
    <div class="register">
        <div class="banner">
            <img :src="liveBanner | urlFilter" alt="">
        </div>
        <div class="form">
            <van-form>
                <div class="form-item" v-for="(item, index) in registerList" :key="index">
                    <div :class="item.is_null == '0' ? 'label-item' : 'label-item verify'">{{item.field_display}}</div>
                    <van-field
                        v-if="item.field_type == 'mobile' || item.field_type == 'password' || item.field_type == 'text' || item.field_type == 'real_name' || item.field_type == 'textarea' || item.field_type == 'org'"
                        :type="item.field_type == 'mobile' ? 'number' : item.field_type == 'textarea' ? 'textarea' : 'text'"
                        v-model='item.val'>
                    </van-field>
                    <!-- 单选框 -->
                    <van-radio-group
                        :label="item.field_display"
                        v-if="item.field_type == 'radio'"
                        v-model='item.val'>
                        <van-radio
                            style="margin-left:0.4rem;"
                            v-for="(subItem1, subIndex1) in item.options" 
                            :name="subItem1" 
                            :key="subIndex1">
                            {{subItem1}}
                        </van-radio>
                    </van-radio-group>
                    <!-- 多选框 -->
                    <van-checkbox-group 
                        :label="item.field_display"
                        :required="item.is_null == '0' ? false : true"
                        v-if="item.field_type == 'checkbox'"
                        v-model='item.val'>
                        <van-checkbox
                            v-for="(subItem2, subIndex2) in item.options" 
                            :name="subItem2"
                            :key="subIndex2">
                            {{ subItem2 }}
                        </van-checkbox>
                    </van-checkbox-group>
                    <!-- 选择框 -->
                    <div class="select-item" v-if="item.field_type == 'select' || item.field_type == 'field_province'" >
                        <select v-model="item.val">
                            <option value="">请选择</option>
                            <option v-for="(subItem3, subIndex3) in item.options" :key="subIndex3" :value="subItem3">{{subItem3}}</option>
                        </select>
                    </div>
                    
                    <!-- 日期选择 -->
                    <vue-datepicker-local 
                        v-model="item.val"
                        v-if="item.field_type =='date'" 
                        rangeSeparator="-">
                    </vue-datepicker-local>

                    <div class="hint">{{item.hint}}</div>
                    <div class="infoVal">{{item.infoVal}}</div>
                </div>
                <van-button @click="submitHandle" type="info">{{$t('form.submit')}} <!-- 提交 --></van-button>
            </van-form>
        </div>
    </div>
</template>
<script>
import VueDatepickerLocal from 'vue-datepicker-local'
import moment from 'moment'
import { mapState } from 'vuex'
import { Toast } from 'vant'
export default {
    components: {
        VueDatepickerLocal
    },
    data() {
        return {
            registerList: [],
            liveBanner: ''
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        getRegForm () {
            this.request.get('getRegForm', { type_id: this.$route.params.id, type: 1 }).then((res) => {
                const { live_banner, data } = res
                this.liveBanner = live_banner
                data.forEach((item, index) => {
                    if (item.field_type == 'checkbox') {
                        item.val = []
                    } else if (item.field_type == 'date') {
                        item.val = new Date()
                    } else if (item.field_type == 'real_name') {
                        item.val = this.userInfo.RealName
                    } else if (item.field_type == 'mobile') {
                        item.val = this.userInfo.Mobile
                    } else if (item.field_type == 'org') {
                        item.val = this.userInfo.OrgName
                    } else {
                        item.val = ''
                    }
                    if (item.options && item.options.indexOf('|') != -1) {
                        item.options = item.options.split('|')
                    }
                    if (item.options && item.options.indexOf(',') != -1) {
                        item.options = item.options.split(',')
                    }
                    item.infoVal = ''
                })
                this.registerList = data
            })
        },
        submitHandle () {
            let isVerify = this.verifyHandle()
            let data = {
                type_id: this.$route.params.id,
                type: 1,
                open_id: ''
            }
            this.registerList.forEach((item, index) => {
                data[item.field_code] = item.val
                if (item.field_type == 'date') {
                    data[item.field_code] = moment(item.val).format('YYYY-MM-DD')
                }
            })
            if (isVerify) {
                this.request.post('submitFrom', data).then((res) => {
                    if (res.code == 200) { // 成功
                        localStorage.setItem(`setRegType-${this.$route.params.id}`, 'reg')
                        localStorage.setItem(`setRegId-${this.$route.params.id}`, res.reg_id)
                        this.$router.push({
                            name: 'Index',
                            params: {
                                id: this.$route.params.id
                            }
                        })
                    } else {
                        this.$toast(res.msg)
                    }
                })
            }
        },
        verifyHandle () {
            let flag = []
            this.registerList.forEach((item, index) => {
                if (item.is_null == '0') { // 不校验
                    flag.push(true)
                } else { //校验
                    if (!item.val) { // 值为空
                        if (item.hint_null) {
                            item.infoVal = item.hint_null
                        } else {
                            if (item.field_type == 'select' || item.field_type == 'radio' || item.field_type == 'checkbox' || item.field_type == 'field_province') {
                                item.infoVal = `请选择${item.field_display}`
                            } else if (item.field_type == 'text' || item.field_type == 'mobile' || 
                                    item.field_type == 'real_name' || item.field_type == 'org' || 
                                    item.field_type == 'number' || item.field_type == 'textarea' || item.field_type == 'password') {
                                item.infoVal = `${item.field_display}不能为空`
                            }
                        }
                        flag.push(false)
                    } else {
                        if (item.field_type == 'mobile') { // 手机号
                            let exc = /^[1][1-9][0-9]{9}$/
                            if (exc.test(item.val)) {
                                flag.push(true)
                            } else {
                                flag.push(false)
                                if (item.hint_verify_no) {
                                    item.infoVal = item.hint_verify_no
                                } else {
                                    item.infoVal = '请输入正确的手机号'
                                }
                            }
                        } else { // 其他
                            flag.push(true)
                        }
                    }
                }
            })
            return flag.indexOf(false) == -1 ? true : false
        }
    },
    created () {
        this.getRegForm()
    }
}
</script>
<style lang="less" scope>
#app {
    height: auto !important;
}
.register {
    width: 100%;
    background: #ffffff;
    height: 100%;
    .banner {
        width: 100%;
        margin-bottom: 1rem;
        img {
            width: 100%;
        }
    }
    .form {
        background: #FFFFFF;
        .van-field__control {
            border: 1px solid #e5e5e5 !important;
            height: 1.2rem;
            padding-left: 0.4rem;
        }
        .van-cell {
            padding: 0 1.5rem;
            &:after {
                border-bottom: none;
            }
        }
        .van-checkbox-group, .van-radio-group {
            padding: 0 1rem;
            box-sizing: border-box;
            display: flex;
            div {
                margin-left: 0.4rem;
            }
        }
        .datepicker {
            width: 100%;
            padding: 0 1.5rem;
            box-sizing: border-box;
            &:before {
                right: 1.5rem;
            }
        }
        .select-item {
            width: 100%;
            padding: 0 1.5rem;
            overflow: hidden;
            box-sizing: border-box;
            select {
                border: 1px solid #e5e5e5 !important;
                width: 100%;
                height: 1.2rem;
                padding-left: 0.4rem;
            }
        }
        .label-item {
            padding-left: 1.4rem;
            box-sizing: border-box;
            font-size: 0.54rem;
            margin-bottom: 0.5rem;
        }
        .verify {
            position: relative;
            &:before {
                content: '*';
                color: red;
                position: absolute;
                left: 1rem;
                top: 0rem;
                font-size: 0.8rem;
            }
        }
        .hint {
            padding-left: 1.4rem;
            box-sizing: border-box;
            color: red;
            font-size: 0.46rem;
            margin-top: 0.1rem;
        }
        .infoVal {
            padding-left: 1.4rem;
            box-sizing: border-box;
            color: red;
            font-size: 0.46rem;
            margin-bottom: 1rem;
        }
        .van-button {
            width: 5rem;
            margin-left: 50%;
            transform: translate(-50%, 0);
            margin-bottom: 1rem;
        }
    }
}
</style>