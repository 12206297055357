<template>
    <div class="lookPower">
        <!-- 登记观看 -->
        <register v-if="lookPower == 2"></register>
        <!-- 密码观看 -->
        <live-password v-if="lookPower == 3"></live-password>
        <!-- 邀请码观看 -->
        <invite-code v-if="lookPower == 8"></invite-code>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import register from '@/components/register/index.vue'
import livePassword from '@/components/live-password.vue/index.vue'
import inviteCode from '@/components/invite-code/index.vue'
export default {
    components: {
        register,
        livePassword,
        inviteCode
    },
    data() {
        return {
            lookPower: ''
        }
    },
    methods: {
    },
    mounted () {
        this.lookPower = this.$route.params.lookPowerType
    }
}
</script>
<style lang="less" scope>
body,html {
    width: 100%;
    height: 100%;
    background: var(--bodyColor);
}
.lookPower {
    width: 100%;
    max-width: 540px;
    height: 100%;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    &::-webkit-scrollbar-track-piece {
        background: rgba(0, 0, 0, 0.1);
        -webkit-border-radius: 6px;
    }
}
</style>