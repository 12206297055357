<!-- 邀请码观看 -->
<template>
    <div class="invite-code">
        <div class="banner">
            <img :src="live_banner | urlFilter" alt="">
        </div>
        <div class="form">
            <van-form ref="myForm">
                <div class="form-item reaName">
                    <van-field
                        type="text"
                        v-model='realName'
                        :rules="rulesRealName"
                        :label="$t('form.name')"
                        required
                        :placeholder="$t('form.placeHolder')+$t('form.name')">
                    </van-field>
                </div>
                <div class="form-item">
                    <van-field
                        type="text"
                        v-model='mobile'
                        :label="$t('form.mobile')"
                        required
                        :placeholder="$t('form.placeHolder')+$t('form.mobile')"
                        :rules="rulesMobile">
                    </van-field>
                </div>
                <div class="form-item">
                    <van-field
                        type="text"
                        v-model='code'
                        :label="$t('form.inviteCode')"
                        :placeholder="$t('form.placeHolder')+$t('form.inviteCode')"
                        required
                        :rules="rulesCode">
                    </van-field>
                </div>
                <van-button type="info" @click="submit">{{$t('form.confirm')}}</van-button>
            </van-form>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { Dialog } from 'vant'
export default {
    data() {
        return {
            realName: '',
            mobile: '',
            code: '',
            rulesRealName: [
                {
                    required: true,
                    message:this.$t('form.name')+this.$t('form.requiredText'),
                    trigger: 'onBlur/onChange'
                }
            ],
            rulesMobile: [
                {
                    required: true,
                    message: this.$t('form.mobile')+this.$t('form.requiredText'),
                    trigger: 'onBlur/onChange'
                },
                {
                    validator: value => {
                        return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(value)
                    },
                    message: this.$t('form.regMobileText'),
                    trigger: 'onBlur/onChange'
                }
            ],
            rulesCode: [
                {
                    required: true,
                    message: this.$t('form.inviteCode')+this.$t('form.requiredText'),
                    trigger: 'onBlur/onChange'
                }
            ],
            live_banner: ''
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        checkInviteCode () {
            this.request.post('checkInviteCode', {
                type_id: this.$route.params.id,
                type: 1,
                real_name: this.realName,
                mobile: this.mobile,
                code: this.code
            }).then((res) => {
                if (res.code != 200) { // 校验失败
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                    })
                } else {
                    localStorage.setItem(`setRegType-${this.$route.params.id}`, 'code')
                    localStorage.setItem(`setRegId-${this.$route.params.id}`, res.reg_id)
                    this.$router.push({
                        name: 'Index',
                        params: {
                            id: this.$route.params.id
                        }
                    })
                }
            })
        },
        submit () {
            this.$refs.myForm.validate().then(() => {
                this.checkInviteCode()
            }).catch(() => {
                
            })
        }
    },
    mounted () {
        this.realName = this.userInfo.RealName
        this.mobile = this.userInfo.Mobile
        this.live_banner = localStorage.getItem('liveBanner')
    }
}
</script>
<style lang="less" scope>
.invite-code {
    width: 100%;
    background: #ffffff;
    height: 100%;
    .banner {
        width: 100%;
        margin-bottom: 1rem;
        img {
            width: 100%;
        }
    }
    .form {
        background: #FFFFFF;
        .form-item {
            margin-bottom: 1rem;
           .van-cell {
                &:before {
                    left: 1.5rem;
                }
                padding: 0 1.5rem;
                .van-field__label {
                    width: 2rem;
                    text-align: right;
                }
                .van-field__control {
                    border: 1px solid #e5e5e5 !important;
                    height: 1.2rem;
                    padding-left: 0.4rem;
                }
            }
        }
        .reaName {
            .van-cell {
                &:before {
                    left: 2rem;
                }
            }
        }
        .van-button {
            width: 5rem;
            margin-left: 50%;
            transform: translate(-50%, 0);
            margin-bottom: 1rem;
        }
    }
}
</style>