<!-- 输入密码观看 -->
<template>
    <div class="live-password">
        <div class="box">
            <div class="title">{{$t('form.welcome')}}</div>
            <div class="content">
                <van-field
                    :placeholder="$t('form.placeHolder')+$t('form.regInfo')"
                    v-model='val'>
                </van-field>
                <van-button type="info" @click="submitHandle">{{$t('form.confirm')}}</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { Dialog } from 'vant'
export default {
    data() {
        return {
            val: ''
        }
    },
    computed: {
    },
    methods: {
        submitHandle () {
            if(!this.val){
                 Dialog.alert({
                    message: this.$t('form.placeHolder')+this.$t('form.regInfo')
                })
                return false;
            }
            this.request.post('checkMm', {
                type_id: this.$route.params.id,
                type: 1,
                mm_code: this.val
            }).then((res) => {
                if (res.code == 200) { // 验证通过
                    localStorage.setItem(`setRegType-${this.$route.params.id}`, 'password')
                    this.$router.push({
                        name: 'Index',
                        params: {
                            id: this.$route.params.id,
                        }
                    })
                } else {
                    Dialog.alert({
                        message: res.msg
                    })
                }
            })
        }
    },
    created () {
    }
}
</script>
<style lang="less" scope>
.live-password {
    width: 100%;
    height: 100%;
    background: #000000;
    .box {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 13rem;
        background: #FFFFFF;
        border-radius: 5px;
        overflow: hidden;
        .title {
            width: 100%;
            height: 1.5rem;
            line-height: 1.5rem;
            font-size: 0.54rem;
            color: #546e7a;
            font-weight: bold;
            padding: 0 0.77rem;
            background-color: #f6f7fb;
            border-bottom: 1px solid #eceff1
        }
        .content {
            padding: 1rem 1.5rem 0 1.5rem;
            box-sizing: border-box;
            overflow: hidden;
            .van-cell {
                padding: 0;
            }
            .van-field__control {
                border: 1px solid #d2d2d2;
                height: 1.55rem;
                padding-left: 0.2rem;
                box-sizing: border-box;
            }
            .van-button {
                width: 100%;
                height: 1.55rem;
                margin-top: 1rem;
                margin-bottom: 2rem;
            }
        }
    }
}
</style>